import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Maska from 'maska'

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";


// Plugin vue-final-modal
import {vfmPlugin, VfmOptions, VueFinalModalProperty } from 'vue-final-modal';
//Plugin pour les events
import mitt from 'mitt';
//Moment JS
import moment from "moment";

const app = createApp(App);
//
app.config.globalProperties.$canAccesToLocalData = process.env.VUE_APP_ONLINE === 'true'


app.use(Maska);
app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(vfmPlugin({
  key: '$vfm',
  componentName: 'VueFinalModal',
  dynamicContainerName: 'ModalsContainer'
}))

const emitter = mitt(); 
app.config.globalProperties.emitter = emitter

ApiService.init(app);
//MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

app.config.globalProperties.$filters = {
  dateToString(value, format) {
     return moment(value).format(format);
  },

  formatToMb(value): number {
    return value/1000000
  }
}
