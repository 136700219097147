enum Actions {
  GET_RESPONSABLES = "getResponsables",
  GET_DECLARATION = "getDeclaration",
  GET_RESEAU_BY_ID = "getReseauById",
  GET_DOCUMENTS_BY_RESEAU = "getDocumentsByReseau",
  GET_RESEAUX_BY_DECLARATION = "getReseauxByDeclaration",
  DELETE_RESEAU_BY_ID = "deleteReseauById",
  GET_COMMENTS_BY_DECLA = "getCommentsByDecla",
  GET_COMMENTS_BY_DECLARATION_ID = "getCommentsByDeclarationId",
  GET_DECLA_BY_PERM_BY_STATUT = "getDeclaByPermByStatut"
}

enum Mutations {
  SET_RESPONSABLES = "setResponsables",
  SET_DECLARATION = "setDeclaration",
  SET_RESEAU_BY_ID = "setReseauById",
  SET_DOCUMENTS_BY_RESEAU = "setDocumentsByReseau",
  SET_RESEAUX_BY_DECLARATION = "setReseauxByDeclaration",
}

export { Actions, Mutations };
