import ApiService from "@/core/services/ApiService";
import * as JWT from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import _ from "lodash";
import { Reseau } from "@/core/models/reseaux/reseau";

@Module
export default class ReseauModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _reseau: Reseau|null = null;

  /**
   * Get one reseau
   * @returns Reseau
   */
  get reseau(): Reseau|null {
      return this._reseau;
  }

  @Mutation
  [Mutations.SET_RESEAU_BY_ID](payload)
  {
      this._reseau = payload
  }

  @Action
  async [Actions.GET_RESEAU_BY_ID](reseauId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/api/reseau/${reseauId}`)
        .then((res) => {
          // this.context.commit(Mutations.SET_RESEAU_BY_ID, data);
          resolve(res.data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  async [Actions.GET_DOCUMENTS_BY_RESEAU](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/api/documents/reseau/${params.reseauId}`, {
        params: params.urlParameters
      })
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_DOCUMENTS_BY_RESEAU, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  async [Actions.GET_RESEAUX_BY_DECLARATION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/api/declarations/${payload.declarationId}/reseaux/${payload.typeReseau}`, {
        params: {
          size: payload.size
        }
      })
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_RESEAUX_BY_DECLARATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  async [Actions.DELETE_RESEAU_BY_ID](reseauId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`/api/reseau/${reseauId}`)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_RESEAUX_BY_DECLARATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
