
import { defineComponent, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      store.dispatch(Actions.INIT_ENTGEOS_ACTION);
      store.dispatch(Actions.GET_TYPE_DOCUMENT_PAR_RESEAU_ACTION);
      store.dispatch(Actions.GET_RESEAU);
      store.dispatch(Actions.GET_PAYS);

//       setInterval(() =>{
//         if (JwtService.getToken()){
//         }
        
//       }, 5000)

    });

    onBeforeMount(() => {
      if(!JwtService.getToken() || !JwtService.getAuthUser()){
        location.href = "#/sign-in";
      }
    })
  },
});
