enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  CHANGE_PASSWORD = "changePassword",
  INIT_PASSWORD = "initPassword",
  VERIFY_EMAIL = "verifyEmail",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_ENTGEOS_ACTION = "fetchEntGeos",
  INIT_ENTGEOS_ACTION = "initEntGeos",
  GET_PAYS = "getPays",
  GET_PROVINCES = "getProvinces",
  GET_VILLES = "getVilles",
  GET_TYPE_MATERIEL = "getTypeMateriels",
  AUTO_AUTH = "AuthUserAutomatically",
  GET_BANDE_PAR_RESEAU_ACTION ="getBandeParReseau",
  GET_TYPE_DOCUMENT_PAR_RESEAU_ACTION ="getTypeDocumentParReseau",
  GET_RESEAU= "getReseau",
  GET_RESPONSABLES = "getResponsables",
  GET_SOUS_BANDE_PAR_BANDE ="getSousBandeParBande",
  RESET_SOUS_BANDE ="resetSousBande",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_ENTGEOS = "setEntGeos",
  SET_PAYS = "setPays",
  SET_PROVINCES = "setProvinces",
  SET_DEPARTEMENTS = "setDepartements",
  SET_VILLES = "setVilles",
  SET_TYPE_MATERIELS = "setTypeMateriels",
  SET_BANDE= "setBande",
  SET_RESEAU_AVEC_TYPE_DOCUMENT= "setReseauAvecTypeDocument",
  SET_RESEAU= "setReseau",
  SET_SOUS_BANDE= "setSousBande",
}

export { Actions, Mutations };
