import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { canAccessToLocalData } from "@/core/helpers/config";
import BandesListe  from "@/store/data/bandes";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import _ from "lodash";

export interface Bande {
  id: string|undefined;
  libelle: string;
  debut: number|undefined;
  fin: number|undefined;
}

export interface Reseau {
  id: string;
  libelle: string;
  bandes: Array<Bande> ;
}


@Module
export default class BandeModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _bandes = [] as Bande[];
  _reseaux = [] as Reseau[];
  _defaultBande : Bande =     
  {
    libelle: "Autre",
    id: "xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs",
    debut: undefined,
    fin: undefined,
  } 

  /**
   * Retourne la bande par défaut
   * @returns Bande
   */
   get defaultBande(): Bande {
    return this._defaultBande;
  }

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
  get bandes(): Array<Bande> {
    return this._bandes;
  }

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
   get reseaux(): Array<Reseau> {
    return this._reseaux;
  }

  @Mutation
  [Mutations.SET_BANDE](payload)
  {
    this._bandes = payload
  }

  @Mutation
  [Mutations.SET_RESEAU](payload)
  {
    this._reseaux = payload
  }

  @Action
  async [Actions.GET_BANDE_PAR_RESEAU_ACTION](reseau : string) {
        return new Promise<void>((resolve, reject) => {
          ApiService.get("guest/bandes/reseau/"+reseau.toLowerCase())
            .then(({ data }) => {
              this.context.commit(Mutations.SET_BANDE, data);
              resolve();
            }).catch(({ response }) => {
              // this.context.commit(Mutations.SET_ERROR, response.data.errors);
              reject();
            });
        });
      }
  @Action
  async [Actions.GET_RESEAU]() {
    var __bandes : Array<Bande> = []

      if(!canAccessToLocalData.value)
     {
        return new Promise<void>((resolve, reject) => {
          ApiService.get("guest/bandes/reseau")
            .then(({ data }) => {
              var reseaux = data;
              reseaux.forEach(reseau => 
              {
                __bandes = [...__bandes, ...reseau.bandes]
                reseau.bandes.push(this._defaultBande)
              })

              this.context.commit(Mutations.SET_RESEAU, reseaux);
              
              __bandes.push(this._defaultBande)
              this.context.commit(Mutations.SET_BANDE, __bandes);
              resolve();
            }).catch(({ response }) => {
              // this.context.commit(Mutations.SET_ERROR, response.data.errors);
              reject();
            });
        });
      }
      else
      {
        var reseaux = BandesListe;
        reseaux.forEach(reseau => 
        {
          
          __bandes = [...__bandes, ...reseau.bandes]
          reseau.bandes.push(this._defaultBande)
        })

        // on ajoute autre à la liste des bandes
        __bandes.push(this._defaultBande)
        this.context.commit(Mutations.SET_RESEAU, BandesListe);
        this.context.commit(Mutations.SET_BANDE, __bandes);
      }
      }
}
