import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { TypeDocument } from "@/core/models/TypeDocument";
import typeDocument from "@/store/data/typeDocuments";

import _ from "lodash";
import typeDocumentList from "@/store/data/typeDocuments";

export interface TypeReseau {
  id: string;
  nom: string;
  typeDocuments : Array<TypeDocument>;
}


@Module
export default class TypeDocumentModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _reseau_avec_type_documents = [];


  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
  get reseau_avec_type_document(): Array<TypeReseau> {
    return this._reseau_avec_type_documents;
  }


  @Mutation
  [Mutations.SET_RESEAU_AVEC_TYPE_DOCUMENT](payload)
  {
    this._reseau_avec_type_documents = payload
  }

  @Action
  async [Actions.GET_TYPE_DOCUMENT_PAR_RESEAU_ACTION](params=false) {
    if(!params)
    {
      return new Promise<void>((resolve, reject) => {
        ApiService.get("guest/typereseaux")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_RESEAU_AVEC_TYPE_DOCUMENT, data);
            resolve();
          }).catch(({ response }) => {
            // this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    }
    else
    {
      this.context.commit(Mutations.SET_RESEAU_AVEC_TYPE_DOCUMENT, typeDocumentList);
    }

  }

}
