
export interface TypeReseau {
  id: string;
  libelle: string;
  typeDocuments : Array<TypeDocument>;
}

interface TypeDocument {
  createdDate?: Date | string;
  lastModifiedDate?: Date | string;
  id: string;
  libelle: string;
}

const typeDocumentList : Array<TypeReseau> = [
  
    {

      id: "103b9ef9-bd99-4fdd-8a3d-625325ab75fb",
      libelle : "FH",
      "typeDocuments": [
        {
          id:"52bf6021-c640-41d2-9c67-ad1afce8c2e4",
          libelle : "Schema directeur"
        },
        {
          id:"1123944e-44a8-44f7-a6f7-16a552e43ee2",
          libelle : "contrat Création d'entreprise "
        },
      ],
    },
    {

      id: "b3ec4684-75ad-4870-9afc-26f1f47e4cd9",
      libelle : "BWWR",
      "typeDocuments": [
        {
          id:"52bf6021-c640-41d2-9c67-ad1afce8c2e4",
          libelle : "Schema directeur"
        },
        {
          id:"1123944e-44a8-44f7-a6f7-16a552e43ee2",
          libelle : "contrat Création d'entreprise "
        },
      ],
    },
    {

      id: "baf5f596-d3f9-49d3-82ce-c17d0f119ad5",
      libelle : "VSAT",
      "typeDocuments": [
        {
          id:"52bf6021-c640-41d2-9c67-ad1afce8c2e4",
          libelle : "Schema directeur"
        },
        {
          id:"1123944e-44a8-44f7-a6f7-16a552e43ee2",
          libelle : "contrat Création d'entreprise "
        },
        {
          id:"3fc17379-a744-4eea-8b05-f5639e9a60ae",
          libelle : "Fiche Techniques"
        },
      ],
    },
    {

      id: "baf5f596-d3f9-49d3-82ce-c17XDD119ad5",
      libelle : "GSM",
      "typeDocuments": [
        {
          id:"52bf6021-c640-41d2-9c67-ad1afce8c2e4",
          libelle : "Schema directeur"
        },
        {
          id:"1123944e-44a8-44f7-a6f7-16a552e43ee2",
          libelle : "contrat Création d'entreprise "
        }
      ],
    },
    {

      id: "59995256-cf63-4fa0-ad27-b7ecf9c7f531",
      libelle : "PMR",
      "typeDocuments": [
        {
          id:"52bf6021-c640-41d2-9c67-ad1afce8c2e4",
          libelle : "Schema directeur"
        },
        {
          id:"1123944e-44a8-44f7-a6f7-16a552e43ee2",
          libelle : "contrat Création d'entreprise "
        },
      ],
    }
  ]

  export default typeDocumentList;