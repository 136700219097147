import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { defineComponent, getCurrentInstance } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/declarations",
        name: "declarations",
        component: () => import("@/views/pages/declarations/DeclarationsList.vue"),
      },
      {
        path: "/declarations/new",
        name: "declarations-new",
        component: () => import("@/views/pages/declaration/form/DeclarationNew.vue"),
      },
      {
        path: "/declarations/edit/:id",
        name: "declarations-edit",
        component: () => import("@/views/pages/declaration/form/DeclarationNew.vue"),
      },
      {
        path: "/declarations/details/:id",
        name: "declaration-details",
        component: () => import("@/views/pages/declaration/details/DeclarationDetails.vue"),
      },
      /** Routes pour l'ajout et la modification des réseaux */
      //Formualire BLR
      {
        path: "/declarations/:declarationId/add/blr",
        component: () => import("@/views/pages/reseau/new/blr/DeclarationBlr.vue"),
        name: "declaration-add-blr",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/blr",
        component: () => import("@/views/pages/reseau/new/blr/DeclarationBlr.vue"),
        name: "declaration-edit-blr",
      },
      //Formulaire FH
      {
        path: "/declarations/:declarationId/add/fh",
        component: () => import("@/views/pages/reseau/new/fh/DeclarationFh.vue"),
        name: "declaration-add-fh",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/fh",
        component: () => import("@/views/pages/reseau/new/fh/DeclarationFh.vue"),
        name: "declaration-edit-fh",
      },
      //Formulaire GSM
      {
        path: "/declarations/:declarationId/add/gsm",
        component: () => import("@/views/pages/reseau/new/gsm/DeclarationGsm.vue"),
        name: "declaration-add-gsm",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/gsm",
        component: () => import("@/views/pages/reseau/new/gsm/DeclarationGsm.vue"),
        name: "declaration-edit-gsm",
      },
      //Formulaire pmr
      {
        path: "/declarations/:declarationId/add/pmr",
        component: () => import("@/views/pages/reseau/new/pmr/DeclarationPmr.vue"),
        name: "declaration-add-pmr",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/pmr",
        component: () => import("@/views/pages/reseau/new/pmr/DeclarationPmr.vue"),
        name: "declaration-edit-pmr",
      },
      //Formualire radar
      {
        path: "/declarations/:declarationId/add/radar",
        component: () => import("@/views/pages/reseau/new/radar/DeclarationRadar.vue"),
        name: "declaration-add-radar",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/radar",
        component: () => import("@/views/pages/reseau/new/radar/DeclarationRadar.vue"),
        name: "declaration-edit-radar",
      },
      //Formulaire vsat
      {
        path: "/declarations/:declarationId/add/vsat",
        component: () => import("@/views/pages/reseau/new/vsat/DeclarationVsat.vue"),
        name: "declaration-add-vsat",
      },
      {
        path: "/declarations/:declarationId/reseau/:reseauId/edit/vsat",
        component: () => import("@/views/pages/reseau/new/vsat/DeclarationVsat.vue"),
        name: "declaration-edit-vsat",
      },


      /** Route détails du réseau */
      {
        path: "/declarations/:declarationId/reseaux/details/:reseauId/:typeReseau",
        name: "reseau-details",
        component: () => import("@/views/pages/reseau/details/ReseauDetails.vue"),
      },
      /** Route responsable technique */
      {
        path: "/responsables-techniques",
        name: "responsables-techniques",
        component: () => import("@/views/pages/responsables/ResponsablesList.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/permissionnaire/profile",
        name: "permissionnaire-account",
        component: () => import("@/views/pages/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "permissionnaire-account-overview",
            component: () => import("@/views/pages/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "permissionnaire-account-settings",
            component: () => import("@/views/pages/account/Settings.vue"),
          },
        ],
      },
    ],
  },
  
  {
    path: "/",
    component: () => import("@/views/guest/Guest.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/pages/accueil/Home.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
      {
        path: "/mail-verification/:key",
        name: "mail-verification",
        component: () => import("@/views/auth/MailVerification.vue"),
      },
      {
        path: "/reset-pwd-permissionnaire/:key",
        name: "change-password",
        component: () => import("@/views/auth/ChangePassword.vue"),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth', top:100 }
    }
  },
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((e) => {
  //Rédirection à la page de connexion si l'utilsateur plus authentifié
  if(e.path !== '/' && !e.path.includes('/sign-in') && !e.path.includes('/mail-verification') 
      && !e.path.includes('/password-reset') && !e.path.includes('/reset-pwd-permissionnaire')){

    if(!JwtService.getToken() || !JwtService.getAuthUser()){
      location.href = "#/sign-in";
    }

  }
  
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);
  

  // Scroll page to top on every route change
  setTimeout(() => {
    if(!e.hash)
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
