import ApiService from "@/core/services/ApiService";
import * as JWT from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import _ from "lodash";
import { ResponsableTechnique } from "@/core/models/ResponsableTechnique";

@Module
export default class RespTechModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _responsables = [];

  /**
   * Get array responsables
   * @returns Array<ResponsableTechnique>
   */
  get responsables(): Array<ResponsableTechnique> {
      return this._responsables;
  }

  @Mutation
  [Mutations.SET_RESPONSABLES](payload)
  {
      this._responsables = payload
  }

  @Action
  async [Actions.GET_RESPONSABLES](size = 100) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/responsableTechnique/permissionnaires/"+JWT.getAuthUser().id, '?size='+size)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSABLES, data);
          resolve();
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  private restructureData(data: any) {

  }
}
