import ApiService from "@/core/services/ApiService";
import * as JWT from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import _ from "lodash";
import { Declaration } from "@/core/models/declarations/declaration";

@Module
export default class DeclarationModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _declaration: Declaration|null = null;

  /**
   * Get one declaration
   * @returns Declaration
   */
  get responsable(): Declaration|null {
      return this._declaration;
  }

  @Mutation
  [Mutations.SET_DECLARATION](payload)
  {
      this._declaration = payload
  }

  @Action
  async [Actions.GET_DECLARATION](declarationId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/api/declaration/${declarationId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DECLARATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  /**
   * Commentaires par déclaration
   * @param id 
   * @returns 
   */
  @Action
  async [Actions.GET_COMMENTS_BY_DECLA](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/api/commentaires/declaration/${id}`)
        .then(({data}) => {
          // this.context.commit(Mutations.SET_DECLARATIONS, {data});
          resolve(data);
        })
        .catch(({ error }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(error);
        });
    });
  }

  /**
   * Liste des déclarations par statut
   * @returns 
   */
   @Action
   async [Actions.GET_DECLA_BY_PERM_BY_STATUT](statut: String) {
     return new Promise<void>((resolve, reject) => {
       ApiService.query(`/api/declarations/permissionnaire/${JWT.getAuthUser().id}`, {
        params: {
          size: 1000000
        }
       })
         .then(({data}) => {
           // this.context.commit(Mutations.SET_DECLARATIONS, {data});
           let notifications: any[] = [];
           
           data.content.forEach(element => {
            if(element.statut.libelle === statut){
              notifications.push(element);
            }
           });
           
           resolve(notifications as any);
         })
         .catch(({ error }) => {
           // this.context.commit(Mutations.SET_ERROR, response.data.errors);
           reject(error);
         });
     });
  }
}
