const ID_TOKEN_KEY = "id_token" as string;
const AUTH_USER = "auth_user" as string;
const CONFIG = "config" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
 export const getAuthUser = (): any | null => {

  if(window.localStorage.getItem(AUTH_USER) === 'undefined') return null;
  
  return JSON.parse(window.localStorage.getItem(AUTH_USER) as string);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save permissionnaire into localStorage
 * @param permissionnaire: any
 */
export const saveAuthUser = (permissionnaire: any): void => {
  window.localStorage.setItem(AUTH_USER, JSON.stringify(permissionnaire));
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(CONFIG);
  window.localStorage.removeItem(AUTH_USER);
};

export default { getToken, getAuthUser, saveToken, saveAuthUser, destroyToken };
