import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { canAccessToLocalData } from "@/core/helpers/config";
import BandesListe  from "@/store/data/bandes";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import _ from "lodash";
import { AutreBande } from "@/core/models/enum/enumeration";

export interface Bande {
  id: string|undefined;
  directionBande: string;
  debut: number|undefined;
  fin: number|undefined;
}

export interface Reseau {
  id: string;
  libelle: string;
  bandes: Array<Bande> ;
}


@Module
export default class SousBandeModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _sous_bandes = [] as Bande[];
  _defaultBandeUL : Bande =     
  {
    directionBande: "UL",
    id: AutreBande.SBUL,
    debut: undefined,
    fin: undefined,
  }
  
  _defaultBandeDL : Bande =     
  {
    directionBande: "DL",
    id: AutreBande.SBDL,
    debut: undefined,
    fin: undefined,
  } 

  /**
   * Retourne la bande par défaut
   * @returns Bande
   */
   get defaultBandeUL(): Bande {
    return this._defaultBandeUL;
  }

  /**
   * Retourne la bande par défaut
   * @returns Bande
   */
   get defaultBandeDL(): Bande {
    return this._defaultBandeDL;
  }

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
  get sousBandes(): Array<Bande> {
    return this._sous_bandes;
  }


  @Mutation
  [Mutations.SET_SOUS_BANDE](payload)
  {
    this._sous_bandes = payload
    this._sous_bandes.push(this._defaultBandeDL)
    this._sous_bandes.push(this._defaultBandeUL)
  }


  @Action
  async [Actions.GET_SOUS_BANDE_PAR_BANDE](bandeID : string) {
        return new Promise<void>((resolve, reject) => {
          ApiService.get("guest/bande/"+bandeID.toLowerCase())
            .then(({ data }) => {
              this.context.commit(Mutations.SET_SOUS_BANDE, data.sousBandes);
              resolve();
            }).catch(({ response }) => {
               this.context.commit(Mutations.SET_SOUS_BANDE, []);
               this.context.commit(Mutations.SET_ERROR, response.data.errors);
              reject();
            })
        });
    }

    @Action
    async [Actions.RESET_SOUS_BANDE]() {
        this.context.commit(Mutations.SET_SOUS_BANDE, []);
      }
}
