import { TypeMateriel } from "@/core/models/Materiel";

const typeMaterielList : Array<TypeMateriel> = [
    {
      "createdDate": "2021-10-26T11:27:44.425+00:00",
      "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
      "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75fb",
      "nom": "Basse",
    },
    {
      "createdDate": "2021-10-26T11:27:44.425+00:00",
      "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
      "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75fb",
      "nom": "Relais",
    },
  ]

  export default typeMaterielList;