import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import entgeosList from "@/store/data/entgeos";
import _ from "lodash";
import { canAccessToLocalData } from "@/core/helpers/config";

export interface TypeEntGeo {
  createdDate: Date|string;
  lastModifiedDate: Date|string;
  id: string;
  nom: string;
}

export interface EntGeo {
  createdDate: Date|string;
  lastModifiedDate: Date|string;
  id: string;
  nom: string;
  parent: EntGeo | undefined;
  typeEntGeo: TypeEntGeo;
}

export interface UserAuthInfo {
    _entgeos: Array<EntGeo>,
    loading:boolean,
    loaded:boolean,
}

@Module
export default class EntGeoModule extends VuexModule  {
  errors = [];
  loading =false;
  loaded =false;
  _entgeos= [];
  _pays = [];
  _provinces = [];
  _departements = [];
  _villes = [];

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
  get entgeos(): Array<EntGeo> {
    return this._entgeos;
  }

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
     get pays(): Array<EntGeo> {
      return this._pays;
    }

  /**
   * Get current user object
   * @returns Array<EntGeo>
   */
      get provinces(): Array<EntGeo> {
      return this._provinces;
    }


    /**
     * Get current user object
     * @returns Array<EntGeo>
     */
      get villes(): Array<EntGeo> {
        return this._villes;
    }


  @Mutation
  [Mutations.SET_ENTGEOS](payload)
  {
      this._entgeos = payload
  }

  @Mutation
  [Mutations.SET_PAYS](payload)
  {
      this._pays = payload
  }

  @Mutation
  [Mutations.SET_PROVINCES](payload)
  {
      this._provinces = payload
  }

  @Mutation
  [Mutations.SET_DEPARTEMENTS](payload)
  {
      this._departements= payload
  }

  @Mutation
  [Mutations.SET_VILLES](payload)
  {
      this._villes = payload
  }

  @Action
  async [Actions.GET_ENTGEOS_ACTION](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("guest/entGeos/nom/"+params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ENTGEOS, data);
          resolve();
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.INIT_ENTGEOS_ACTION](params=false) {
     if(!canAccessToLocalData.value)
     {
      return new Promise<void>((resolve, reject) => {
        ApiService.get("guest/entGeos")
          .then(({ data }) => {
            
            this.context.commit(Mutations.SET_ENTGEOS, data);
            // on recupere toutes les pays
            // on recupere toutes les pays
            
            const pays = data.filter(p => {
              return p.typeEntGeo.nom == "Pays";
            });
            pays.sort((a, b) => a.nom.localeCompare(b.nom));
            // on recupere toutes les provinces du gabon
            const provinces = data.filter(p => {
              return (p.parent?.nom == "Gabon" && p.typeEntGeo.nom == "Province");
            });
            provinces.sort((a, b) => a.nom.localeCompare(b.nom));
            // on recupere tous les ids des provinces
            const provincesIDS = _.map(provinces, 'id');
        
            //on filtre les départements du Gabon
            const departements = data.filter(p => {
              return (provincesIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Département");
            });
            departements.sort((a, b) => a.nom.localeCompare(b.nom));
            // on recupere tous les ids des provinces
            const departementIDS = _.map(departements, 'id');
        
            //on retourne  les villes du Gabon
            const villes = data.filter(p => {
              return (departementIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Ville");
            });
            villes.sort((a, b) => a.nom.localeCompare(b.nom));

            this.context.commit(Mutations.SET_PAYS, pays);
            this.context.commit(Mutations.SET_PROVINCES, provinces);
            this.context.commit(Mutations.SET_DEPARTEMENTS, departements);
            this.context.commit(Mutations.SET_VILLES, villes);
            resolve();
          })
          .catch(({ response }) => {
            //this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
     }
     else
     {
       var data = entgeosList
      this.context.commit(Mutations.SET_ENTGEOS, data);
      // on recupere toutes les pays
      // on recupere toutes les pays
      // const pays = data.filter(p => {
      //   return p.typeEntGeo.nom == "Pays";
      // });
      // on recupere toutes les provinces du gabon
      const provinces = data.filter(p => {
        return (p.parent?.nom == "Gabon" && p.typeEntGeo.nom == "Province");
      });
  
      // on recupere tous les ids des provinces
      const provincesIDS = _.map(provinces, 'id');
  
      //on filtre les départements du Gabon
      const departements = data.filter(p => {
        return (provincesIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Département");
      });
  
      // on recupere tous les ids des provinces
      const departementIDS = _.map(departements, 'id');
  
      //on retourne  les villes du Gabon
      const villes = data.filter(p => {
        return (departementIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Ville");
      });

      // this.context.commit(Mutations.SET_PAYS, pays);
      this.context.commit(Mutations.SET_PROVINCES, provinces);
      this.context.commit(Mutations.SET_DEPARTEMENTS, departements);
      this.context.commit(Mutations.SET_VILLES, villes);
     }

  }

  @Action
  [Actions.GET_PAYS](params=false) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("guest/entGeos/pays")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  private formateData(data: any) {
    const pays = data.filter(p => {
      return p.typeEntGeo.nom == "Pays";
    });

    // on recupere toutes les provinces du gabon
    const provinces = data.filter(p => {
      return (p.parent?.nom == "Gabon" && p.typeEntGeo.nom == "Province");
    });

    // on recupere tous les ids des provinces
    const provincesIDS = _.map(provinces, 'id');

    //on filtre les départements du Gabon
    const departements = data.filter(p => {
      return (provincesIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Département");
    });

    // on recupere tous les ids des provinces
    const departementIDS = _.map(departements, 'id');

    //on retourne  les villes du Gabon
    const villes = data.filter(p => {
      return (departementIDS.includes(p.parent?.id) && p.typeEntGeo.nom == "Ville");
    });
    return { pays, provinces, departements, villes };
  }

  private restructureData(data: any) {

  }
}
