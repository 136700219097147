 export interface Bande {
      createdDate?: Date|string|undefined;
      lastModifiedDate?: Date|string|undefined;
      id: string| undefined;
      libelle: string;
      debut: number|undefined;
      fin: number|undefined;
  }
  export interface Reseau {
    id : string,
    libelle:string
    bandes: Array<Bande>;

}

const BandesListe : Array<Reseau> = [
  {
    id:"1az",
    libelle:"gsm",
    bandes : [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "5G",
        "id": "103b9ef9-bd99-4fdd-8a3dvfvf-625325ab75fb",
        "debut": 700,
        "fin": 2600,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "2G",
        "id": "103b9ef9-bd99-4fdd-8a3d-6253vvd25ab75fb",
        "debut": 900,
        "fin": 1800,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "3G",
        "id": "103b9ef9-bd99-4fdd-8a3d-625325asdfeb75fb",
        "debut": 900,
        "fin": 2100,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "4G",
        "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75fxsb",
        "debut": 700,
        "fin": 1800,
      }
    ],
  },
  {
    id:"2az",
    libelle:"vsat",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande C",
        "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75cdfxsb",
        "debut": 700,
        "fin": 1800,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande D",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsb",
        "debut": 900,
        "fin": 2700,
      }
     ]
  },
  
  {
    id:"2az",
    libelle:"fh",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 5G",
        "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75cdfxsbazza",
        "debut": 700,
        "fin": 1800,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 4G",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsbazdd",
        "debut": 900,
        "fin": 2700,
      }
     ]
  },
  {
    id:"2asxd",
    libelle:"blr",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 5G",
        "id": "103b9ef9-bd99-4fdd-8a3d-625325ab75cdfxsbazzaazadsd",
        "debut": 700,
        "fin": 1800,
      },
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 4G",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsbazddyughg",
        "debut": 900,
        "fin": 2700,
      }
     ]
  },
  {
    id:"2asxdxxxx",
    libelle:"radar",
    bandes:  []
  },
  {
    id:"2asxdxxxxccdd",
    libelle:"HF",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 4G",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsbazddyughg",
        "debut": 900,
        "fin": 2700,
      }  
    ]
  },
  {
    id:"2asxdxxxxccdd",
    libelle:"UHF/VHF (SIMPLEX)",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 4G",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsbazddyughg",
        "debut": 900,
        "fin": 2700,
      }  
    ]
  },
  {
    id:"2asxdxxxxccdd",
    libelle:"UHF/VHF (DUPLEX)",
    bandes:  [
      {
        "createdDate": "2021-10-26T11:27:44.425+00:00",
        "lastModifiedDate": "2021-10-26T11:27:44.425+00:00",
        "libelle": "Bande 4G",
        "id": "103b9ef9-bd99-4fdd-8a3xsd-625325ab75fxsbazddyughg",
        "debut": 900,
        "fin": 2700,
      }  
    ]
  },
  ]

  export default BandesListe;