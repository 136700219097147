import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { TypeMateriel } from "@/core/models/Materiel";
import _ from "lodash";
import typeMaterielsList from "@/store/data/typeMateriel";


@Module
export default class TypeMaterielModule extends VuexModule  {
  errors = [];
  _typeMateriels = [];

  /**
   * Get current user object
   * @returns Array<EypeMateriel>
   */
  get typeMateriels(): Array<TypeMateriel> {
    return this._typeMateriels;
  }



  @Mutation
  [Mutations.SET_TYPE_MATERIELS](payload)
  {
      this._typeMateriels = payload
  }

  @Action
  async [Actions.GET_TYPE_MATERIEL](params=false) {
   if(!params)
   {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("guest/typemateriels")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_TYPE_MATERIELS, data);
            resolve();
          })
          .catch(({ response }) => {
            // this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
   }
   else
   {
    this.context.commit(Mutations.SET_TYPE_MATERIELS, typeMaterielsList);
   }
  }
}
