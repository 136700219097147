export enum DirectionBande {
    DL = "DL",
    UL = "UL"
}

export enum TypeBande {
    FDD = "FDD",
    TDD = "TDD"
}

export enum TypeEnregistrement {
    AVEC_DECLARATION = "AVEC_DECLARATION",
    SANS_DECLARATION = "SANS_DECLARATION"
}

export enum Code {
    A = "A",
    B = "B"
}

export enum Territoire {
    NATIONAL = "NATIONAL",
    INTERNATIONAL = "INTERNATIONAL"
}

export enum TypePmr {
    HF = "HF",
    SIMPLEX = "UHF/VHF (SIMPLEX)",
    DUPLEX = "UHF/VHF (DUPLEX)"
}

export enum TypeMateriel {
    BASE = "Base",
    RELAIS = "Relais",
    PORTATIF = "Portatif",
    MOBILE = "Mobile"
}

export enum TypeBwwr{
    BLR = "BLR",
    WIFI = "wIFI",
    WIMAX = "WIMAX",
    RADAR = "RADAR",

}

export enum AutreBande{
    SBUL = "xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxsUL",
    SBDL = "xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxsDL",
    BD = "xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs",
}

export enum ACCEPTED_FILETYPE{
    PDF = "pdf",
    PDFM = "PDF",
    DOCX = "docx",
    DOC = "doc",
    WORDDOCX = "document",
    JPEG = "jpeg",
    JPG = "jpg",
    PNG = "png",
    BPM = "bmp",
    EXCEL = "xls",
    EXCELX = "xlsx",
    SHEET = "sheet",
    CSV = "csv",
}

export enum ACCEPTED_FILETYPE_MESSAGE{
    TYPE = "Le document doit avoir les types suivants : PDF, Excel, CSV, DOCx, PNG, JPEG, BMP",
}