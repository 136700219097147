import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EntGeoModule from "@/store/modules/EntGeoModule";
import TypeMaterielModule from "@/store/modules/TypeMaterielModule";
import TypeDocumentModule from "@/store/modules/TypeDocumentModule";
import BandeModule from "@/store/modules/BandeModule";
import RespTechModule from "@/store/modules/RespTechModule";
import SousBandeModule from "@/store/modules/SousBandeModule";
import DeclarationModule from "@/store/modules/DeclarationModule";
import ReseauModule from '@/store/modules/ReseauModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    EntGeoModule,
    TypeMaterielModule,
    TypeDocumentModule,
    BandeModule,
    RespTechModule,
    SousBandeModule,
    DeclarationModule,
    ReseauModule
  },
});

export default store;
